<template>
  <div class="gmain clearfix">
    <div class="gmain-left-left">
      <div class="border-container1">
        <el-menu
          :default-active="activeMenu"
          class="el-menu-vertical-demo"
           @select="handleSelect"
          background-color="transparent"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <router-link :to="{ name: 'Byq' }">
            <el-menu-item index="sensorByqInfo-0">
              <i class="el-icon-s-management"></i>
              <span slot="title">站点管理</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'Kgg' }">
            <el-menu-item index="sensorByqInfo-1">
              <i class="el-icon-s-marketing"></i>
              <span slot="title">设备管理&nbsp;&nbsp;&nbsp;&nbsp;</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'Sensor' }">
            <el-menu-item index="sensorByqInfo-2">
              <i class="el-icon-s-data"></i>
              <span>传感器管理</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'Dic' }">
            <el-menu-item index="sensorByqInfo-3">
              <i class="el-icon-s-data"></i>
              <span>字典类型管理</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'DicData' }">
            <el-menu-item index="sensorByqInfo-4">
              <i class="el-icon-s-data"></i>
              <span>字典数据管理</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'jxtData' }">
            <el-menu-item index="sensorByqInfo-5">
              <i class="el-icon-more"></i>
              <span slot="title">接线图管理</span>
            </el-menu-item>
          </router-link>
          <router-link :to="{ name: 'caseRecommend' }">
            <el-menu-item index="sensorByqInfo-6">
              <i class="el-icon-more"></i>
              <span slot="title">案例推荐</span>
            </el-menu-item>
          </router-link>
        </el-menu>
      </div>
    </div>
    <div class="gmain-middle">
      <div class="border-container">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenu: "sensorByqInfo-0", // 默认激活的菜单项
    };
  },
  created() {
    // 从sessionStorage中获取之前保存的激活状态
    const savedActive = sessionStorage.getItem("activeMenu");
    if (savedActive) {
      this.activeMenu = savedActive;
    }
  },
  methods:{
     handleSelect(key) {
        sessionStorage.setItem("activeMenu", key);
      }
  },
  beforeDestroy(){
     sessionStorage.setItem("activeMenu", "sensorByqInfo-0");
  }
};
</script>

<style lang="scss" scoped>
.gmain {
  width: 100%;
  height: 100%;
}
.gmain-left-left {
  float: left;
  width: 18%;
  padding: 0 10px;
}
.gmain-middle {
  float: left;
  width: 82%;
  padding: 0 10px;
}
.border-container1 {
  height: calc(100vh - 180px);
  position: relative;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #54dcf2;
  border-radius: 20px;
  overflow-y: scroll;

}
.border-container {
  height: calc(100vh - 180px);
  position: relative;
  margin-top: 15px;
  padding: 10px;
  border: 1px solid #54dcf2;
  border-radius: 20px;
  overflow-y: scroll;
  // background: url(./component/1.png) center no-repeat;
}
.el-menu {
  height: calc(100vh - 180px);
  border-right: 0px;
  list-style: none;
  position: relative;
  margin: 0;
  padding-right: 20px;
}
/deep/ .el-menu-item {
  font-size: 18px;
}
//设置当前被选中的一级菜单
/deep/ .el-menu-item.is-active {
  border: 0;
  background: #246ff8 !important;
}
//设置菜单鼠标经过的样式
/deep/ .el-menu-item:hover {
  background: #307ae9 !important;
}
//设置二级菜单被选中的菜单
/deep/ .el-sub-menu.is-active {
  border: #002df7;
  background: #307ae9 !important;
}
//设置二级菜单鼠标经过的样式
/deep/ .el-sub-menu__title:hover {
  background: #0026fc;
  border: #307ae9 !important;
}
//设置选中之后鼠标移开的背景颜色
/deep/ .el-tooltip__trigger {
  border: transparent !important;
}

::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

::-webkit-scrollbar-track,
::-webkit-scrollbar-thumb {
  border: 0px solid transparent;
}

.titleTable {
  color: rgb(226, 134, 29);
  font-size: 26px;
  margin: 7.5rem;
}
.create-screen {
  height: 100%;
  display: flex;
  flex-direction: column;
  background: rgba(0, 51, 125, 0.07);
  margin: 0;
  padding: 0;
  user-select: none;
}
.top-bar {
  height: 50px;
  width: 100%;
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 20px;
    width: 100%;
    background: #151b22;
  }
}
.right-bar {
  position: absolute;
  left: 180px;
  top: 20px;
  height: 5px;
  width: 100%;
  border-top: 1px solid #2681ff;
  border-left: 2px solid #2681ff;
  background: rgba(55, 126, 255, 0.04);
  border-top-left-radius: 5px;
  transform: skewX(-45deg);
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.left-bar {
  position: absolute;
  left: 0;
  top: 24px;
  height: 25px;
  width: 170px;
  border-right: 2px solid #2681ff;
  border-bottom: 1px solid #2681ff;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
  box-shadow: 0 5px 28px 0 rgba(55, 126, 255, 0.28);
}

.return-btn {
  position: absolute;
  left: -11px;
  top: 0;
  width: 180px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  background: #151b22;
  cursor: pointer;
  transform: skewX(-45deg);
  border-bottom-right-radius: 5px;
}
.return-text {
  display: inline-block;
  color: #fff;
  font-size: 14px;
  margin-left: 10px;
  transform: skewX(45deg);
  transition: 0.2s;

  &:hover {
    color: #2681ff;
  }
}
//  .return-data-btn {
//       position: absolute;
//       left: 100px;
//       top: 0;
//       width: 180px;
//       height: 48px;
//       line-height: 48px;
//       text-align: center;
//       background: #151b22;
//       cursor: pointer;
//       transform: skewX(-45deg);
//       border-bottom-right-radius: 5px;
//     }
//   .return-data {
//     display: inline-block;
//     color: #fff;
//     font-size: 14px;
//     margin-left: 10px;
//     transform: skewX(45deg);
//     transition: 0.2s;

//     &:hover {
//       color: #2681ff;
//     }
//   }
.return-icon {
  margin-right: 5px;
}
// 表格相关的配置

.success-row {
  color: greenyellow;
}
.warning-row {
  color: red;
}
.buttonText {
  // 做到只设置一次，就可以使所有a标签的四种状态都和本身颜色保持一致，样式代码该怎么写
  color: #409eff;
  text-decoration: underline;
}
.el-form {
  /deep/ .el-form-item__label {
    padding-right: 0;
    color: #e5e7ec;
    font-size: 16px;
  }
  .el-form-item {
    margin-bottom: 0;

    &.is-error {
      margin-bottom: 22px;
    }
  }
  // el-input宽度
  /deep/ .form-item {
    > .el-input:not(.el-date-editor) {
      width: 6rem;
    }
  }
  /deep/ .el-select {
    background: #1d262e;
    color: bisque;
  }
  /deep/ .el-input__inner {
    // width: 4.5rem;
    background: #1d262e;
    color: bisque;
    padding: 0 10px;
    line-height: 30px;
    font-size: 16px;
    height: 30px;
    // padding-right: 30px;
    border: 1px solid #75727a;
    transition: 0.2s;
  }
  /deep/ .el-cascader {
    width: 200px;
  }
  /deep/ .el-range-input {
    background-color: transparent;
    color: bisque;
    // width: 4rem;
    font-size: 16px;
  }
  //  /deep/ .el-range-input__inner {
  //     width: 5rem;
  //     background: #1d262e;
  //     color: bisque;
  //     padding: 0 10px;
  //     line-height: 30px;
  //     font-size: 16px;
  //     height: 30px;
  //     // padding-right: 30px;
  //     border: 1px solid #75727a;
  //     transition: 0.2s;
  // }
  /deep/ .el-date-editor .el-range-separator {
    padding: 2px;
    color: bisque;
    font-size: 16px;
  }
}
</style>